// src/templates/posts/Bio.js

import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout.js'
import ResourceCards from '../../components/ResourceCards.js'
import EmailSVG from '../../images/icon-email.svg'
import LinkedinSVG from '../../images/icon-linkedin.svg'
import parse from 'html-react-parser'

function Bio(props) {
  const postData = props.data.wpBio.bio
  const imageSrc = props.data.wpBio.bio.image
    ? getImage(props.data.wpBio.bio.image.localFile)
    : null

  return (
    <Layout>
      <Seo post={props.data.wpBio} />

      <section className="bio-header">
        <div className="container">
          <div className="flex flex-col border-b border-granite-200 py-responsive-xl-80 md:flex-row">
            <div className="bio-image w-full md:w-1/3 md:pr-responsive-xl-32 md-d:mb-responsive-xl-32">
              {imageSrc && (
                <GatsbyImage
                  image={imageSrc}
                  alt={props.data.wpBio.bio.image.altText}
                  className="h-auto w-full"
                />
              )}
            </div>

            <div className="bio-header-text space-y-responsive-xl-16 md:w-2/3">
              <h1 className="headline-xl">
                {postData.firstName} {postData.lastName}
              </h1>

              <p className="headline-md">{postData.role}</p>
              <div className="flex items-center space-x-responsive-xl-16">
                {postData.email && (
                  <a
                    className="bg-noise flex h-[50px] w-[50px] items-center justify-center rounded-full bg-light-gray"
                    href={`mailto:${postData.email}`}
                  >
                    <EmailSVG className="icon fill-boynton-a11y" />
                  </a>
                )}
                {postData.linkedin && (
                  <a
                    className="bg-noise flex h-[50px] w-[50px] items-center justify-center rounded-full bg-light-gray" target="_blank"
                    href={`https://www.linkedin.com/in/${postData.linkedin}`}
                  >
                    <LinkedinSVG className="icon fill-boynton-a11y" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bio-info">
        <div className="container">
          <div className="flex flex-col md:flex-row">
            {(postData.experiences || postData.certificates) && (
              <div className="bio-creds grow space-y-responsive-xl-24 md:w-1/2 md:pr-responsive-xl-16">
                {postData.experiences && (
                  <>
                    <h2 className="headline-sm font-medium">Experience</h2>
                    <div className="bio-experience text">
                      {postData.experiences.map((node, index) => {
                        return <p key={index}>{node.experience}</p>
                      })}
                    </div>
                  </>
                )}
                {postData.certificates && (
                  <>
                    <h2 className="headline-sm font-medium">Certificate</h2>
                    <div className="bio-certs text">
                      {postData.certificates.map((node, index) => {
                        return <p key={index}>{node.certificate}</p>
                      })}
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="bio-description grow md:w-1/2 md:pl-responsive-xl-16">
              {postData.description && <p>{parse(postData.description)}</p>}
            </div>
          </div>
        </div>
      </section>

      {props.pageContext.articles.edges.length > 0 && (
        <div className="container">
          <h2 className="headline-md mb-responsive-xl-64 text-center">
            Articles by {postData.firstName}
          </h2>
          <ResourceCards automatedResources={props.pageContext.articles} />
        </div>
      )}
    </Layout>
  )
}

export default Bio

export const query = graphql`
  query ($id: String!) {
    wpBio(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      bio {
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 720
                width: 900
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        firstName
        lastName
        role
        email
        linkedin
        description
        experiences {
          experience
        }
        certificates {
          certificate
        }
      }
    }
  }
`
